const testimonials = {
  testimonial_data: [
    {
      id: 1,
      text:
        "Great customer service. Their curbside pickup during this pandemic is 2nd to none.",
      by: "Michael, Philadelphia",
      location: "PHILADELPHIA",
    },
    {
      id: 2,
      text:
        "I love it everyone is awesome they take their time with you making sure you are happy.",
      by: "Sandy, Philadelphia",
      location: "PHILADELPHIA",
    },
    {
      id: 3,
      text: "The staff is so knowledgeable and friendly.",
      by: "Darren, Philadelphia",
      location: "PHILADELPHIA",
    },
    {
      id: 4,
      text: "Friendly staff. Relaxing atmosphere. Excellent customer service.",
      by: "JP, Philadelphia",
      location: "PHILADELPHIA",
    },
    {
      id: 5,
      text:
        "Great products. Friendly staff. Excellent rewards program. Good location. They seem to have the most consistency with stock of the concentrates that I like (Prime/GTI) as well as the LLR pens I like.",
      by: "Joshua, Norristown",
      location: "NORRISTOWN",
    },
    {
      id: 6,
      text: "Very helpful staff. Friendly. Easy process.",
      by: "Susan, Norristown",
      location: "NORRISTOWN",
    },
    {
      id: 7,
      text:
        "Best rewards system I've seen. They get good flower a decent amount of the time.",
      by: "Turner, Norristown",
      location: "NORRISTOWN",
    },
    {
      id: 8,
      text:
        "Excellent 20% Veterans discount everyday! Also they have a rewards system which is fantastic. The staff are very friendly.",
      by: "712Colgate, Norristown",
      location: "NORRISTOWN",
    },
    {
      id: 9,
      text:
        "1st time there. Staff was super friendly & helpful. They had an onsite Pharmacist which was a really nice thing for those who don’t know all that much about TCH CBD Terpenes.",
      by: "Tessie, Bensalem",
      location: "BENSALEM",
    },
    {
      id: 10,
      text:
        "Quality products at a fair price. Staff is caring and knowledgeable. They know how to treat a client. Definitely the best in the area.",
      by: "Anthony, Bensalem",
      location: "BENSALEM",
    },
    {
      id: 11,
      text:
        "This dispensary has always been my go to for my mmj medical needs. Everyone is very friendly and helpful. My experiences have all been great.",
      by: "Rodriguez, Bensalem",
      location: "BENSALEM",
    },
    {
      id: 12,
      text: "Friendly and helpful staff. Only one page of paperwork.",
      by: "Steven, Bensalem",
      location: "BENSALEM",
    },
    {
      id: 13,
      text:
        "Can't even put into works how blessed I am to of found such a caring informative dispensary that the employees go Above and beyond to help each and every patient.  The best dispensary I've personally have been to since I've had my card to date. You guys are awesome. Clean facility! I will be back…thank you!",
      by: "Terrah, Aliquippa",
      location: "ALIQUIPPA",
    },
    {
      id: 15,
      text:
        "I'm a first time patient with CRIPPLING anxiety. I was met with friendly faces, attentive staff, and because I didn't have a CLUE what I wanted/needed. The pharmacist gave me her personal recommendations. What a wonderful experience and I can't wait to come back!",
      by: "Evelyn, Aliquippa",
      location: "ALIQUIPPA",
    },
    {
      id: 16,
      text:
        "Nice clean facility with great staff. They are all super helpful and friendly. I like that they are open on Sundays. The have a good selection of products too.",
      by: "Woody, Aliquippa",
      location: "ALIQUIPPA",
    },
    {
      id: 17,
      text:
        "Very helpful and friendly! Expect to spend 15 to 20 minutes if you are ordering in store and 5 to 10 minutes if you are picking up an online order. New customers without previous experience should expect to spend 30 minutes in store on their first visit. Military receives a 20% discount while lower discounts are offered to those with a PA Access Card, Industry Workers etc.",
      by: "Michael, Aliquippa",
      location: "ALIQUIPPA",
    },
    {
      id: 22,
      text:
        "Great customer service—even though I was all over the place, they were patient with me.",
      by: "Crystal, Detroit",
      location: "DETROIT",
    },
    {
      id: 23,
      text:
        "Very pleasant place to visit it was our first time they were helpful and reasonably priced.",
      by: "Shy, Detroit",
      location: "DETROIT",
    },
    {
      id: 24,
      text:
        "The staff was so friendly and helpful as a first-time visitor to any medical [provisioning center]. Good people work here!",
      by: "Max, Detroit",
      location: "DETROIT",
    },
    {
      id: 25,
      text:
        "Not your typical Detroit [provisioning center] experience and design. High prices proxy for the high quality products. Love the atmosphere and was greeted with a warm welcome from the receptionist each time.",
      by: "Robert, Detroit",
      location: "DETROIT",
    },
    {
      id: 26,
      text:
        "The best selection anywhere around here. That keeps me coming back.",
      by: "Scott, Somerville",
      location: "SOMERVILLE",
    },
    {
      id: 27,
      text:
        "Amazing quality, friendly and knowledgeable staff, and some of the best prices and deals I’ve seen in the Massachusetts Medical Marijuana market. A+!",
      by: "Dr. High Horse, Somerville",
      location: "SOMERVILLE",
    },
    {
      id: 28,
      text:
        "Great deals very nice folks and they know how to help you. Love you guys.",
      by: "Richard, Somerville",
      location: "SOMERVILLE",
    },
    {
      id: 29,
      text:
        "I absolutely love this dispensary. First off this dispensary is SUPER CLEAN!! All of the staff is super friendly and personable, very professional, and kind. I am always greeted with a smile even on a bad day, they always know how to make your day better.",
      by: "Amber, Somerville",
      location: "SOMERVILLE",
    },
    {
      id: 30,
      text:
        "Great and friendly service, good selection of items overall. Clean and quiet space to discuss products, security is friendly and helpful. I most certainly will be back.",
      by: "H, Easthampton",
      location: "EASTHAMPTON",
    },
    {
      id: 31,
      text:
        "This place is so incredibly lovely! Definitely my new regular spot, the employees are superb and the selection beats most places around.",
      by: "Julie, Easthampton",
      location: "EASTHAMPTON",
    },
    {
      id: 32,
      text:
        "The place was so pretty and had such a friendly staff! Right off Northampton St., so it’s easy to get to from the highway. Great staff. For sure coming back.",
      by: "O, Easthampton",
      location: "EASTHAMPTON",
    },
    {
      id: 33,
      text:
        "The staff was extremely friendly. The lady who checked me in at the med counter was so sweet and informative. I didn’t catch the name of the budtender, but he was amazing. Definitely will be back! Such an awesome little store and great staff. Keep up the good work.",
      by: "Raquel, Easthampton",
      location: "EASTHAMPTON",
    },
    {
      id: 34,
      text:
        "The staff is great.  They are helpful, and patient and answer my questions no matter how strange. They also have different offers of the week.  Stay healthy and safe.",
      by: "Cottrell, Rockville",
      location: "ROCKVILLE",
    },
    {
      id: 35,
      text:
        "Call me biased, but God I love this place I haven't found any other place that has better deals than this one.",
      by: "Joseph, Rockville",
      location: "ROCKVILLE",
    },
    {
      id: 36,
      text:
        "My daily spot for all the great deals and variety, Liberty has become my favorite in the county because of many reasons, primarily the staff!",
      by: "Mario, Rockville",
      location: "ROCKVILLE",
    },
    {
      id: 37,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "ROCKVILLE",
    },
    {
      id: 38,
      text:
        "I love the way this shop makes you feel when you step through those doors. You are first greeted by an extremely nice receptionist and then you are able to go into the bud-room….",
      by: "Jacob, Beach Center",
      location: "BEACH CENTER",
    },
    {
      id: 39,
      text:
        "Always friendly, curbside pickup is speedy and has yet to get my order wrong.",
      by: "Nick, Beach Center",
      location: "BEACH CENTER",
    },
    {
      id: 40,
      text:
        "Staff is always pleasant and never gives me a hurry up attitude and answer questions about the product and offer helpful info each time I asked them.",
      by: "Carl, Beach Center",
      location: "BEACH CENTER",
    },
    {
      id: 41,
      text: "Been visiting this site for nearly two years. Everyone there is courteous, kind, informative, and helpful. The selection of product is top shelf.",
      by: "Norbert, Beach Center",
      location: "BEACH CENTER",
    },
    {
      id: 42,
      text: "I just love this place … so many varieties.",
      by: "Suzette, Ann Arbor",
      location: "MADISON HEIGHTS",
    },
    {
      id: 43,
      text:
        "The staff was so friendly and helpful as a first-time visitor to any medical [provisioning center]. Good people work here!",
      by: "Max, Detroit",
      location: "MADISON HEIGHTS",
    },
    {
      id: 44,
      text:
        "Great customer service—even though I was all over the place, they were patient with me.",
      by: "Crystal, Detroit",
      location: "MADISON HEIGHTS",
    },
    {
      id: 45,
      text:
        "First time rec patient, went for curbside. WONDERFUL experience! Free Crunch-berries pre-rolls, Liberty brand lighter, and sticker with my large purchase! Budtender was very nice. WILL shop again. Thank you!",
      by: "Destiny, Ann Arbor",
      location: "MADISON HEIGHTS",
    },
    {
      id: 46,
      text: "I just love this place … so many varieties.",
      by: "Suzette, Ann Arbor",
      location: "COLUMBIA",
    },
    {
      id: 47,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "COLUMBIA",
    },
    {
      id: 48,
      text:
        "Great and friendly service, good selection of items overall. Clean and quiet space to discuss products, security is friendly and helpful. I most certainly will be back.",
      by: "H, Easthampton",
      location: "COLUMBIA",
    },
    {
      id: 49,
      text:
        "Nice clean facility with great staff. They are all super helpful and friendly. I like that they are open on Sundays. The have a good selection of products too.",
      by: "Woody, Aliquippa",
      location: "COLUMBIA",
    },
    {
      id: 50,
      text:
        "Staff is always pleasant and never gives me a hurry up attitude and answer questions about the product and offer helpful info each time I asked them.",
      by: "Carl, Beach Center",
      location: "ST. LOUIS (COMING SOON)",
    },
    {
      id: 51,
      text: "Been visiting this site for nearly two years. Everyone there is courteous, kind, informative, and helpful. The selection of product is top shelf.",
      by: "Norbert, Beach Center",
      location: "ST. LOUIS (COMING SOON)",
    },
    {
      id: 52,
      text: "I just love this place … so many varieties.",
      by: "Suzette, Ann Arbor",
      location: "ST. LOUIS (COMING SOON)",
    },
    {
      id: 53,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "ST. LOUIS (COMING SOON)",
    },
    {
      id: 54,
      text:
        "The staff is great.  They are helpful, and patient and answer my questions no matter how strange. They also have different offers of the week.  Stay healthy and safe.",
      by: "Cottrell, Rockville",
      location: "OXON HILL",
    },
    {
      id: 55,
      text:
        "Call me biased, but God I love this place I haven't found any other place that has better deals than this one.",
      by: "Joseph, Rockville",
      location: "OXON HILL",
    },
    {
      id: 56,
      text:
        "My daily spot for all the great deals and variety, Liberty has become my favorite in the county because of many reasons, primarily the staff!",
      by: "Mario, Rockville",
      location: "OXON HILL",
    },
    {
      id: 57,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "OXON HILL",
    },
    {
      id: 58,
      text: "I just love this place … so many varieties.",
      by: "Suzette, Ann Arbor",
      location: "KANSAS CITY",
    },
    {
      id: 59,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "KANSAS CITY",
    },
    {
      id: 60,
      text:
        "Great and friendly service, good selection of items overall. Clean and quiet space to discuss products, security is friendly and helpful. I most certainly will be back.",
      by: "H, Easthampton",
      location: "KANSAS CITY",
    },
    {
      id: 61,
      text:
        "Nice clean facility with great staff. They are all super helpful and friendly. I like that they are open on Sundays. The have a good selection of products too.",
      by: "Woody, Aliquippa",
      location: "KANSAS CITY",
    },

    {
      id: 62,
      text:
        "Staff is always pleasant and never gives me a hurry up attitude and answer questions about the product and offer helpful info each time I asked them.",
      by: "Carl, Beach Center",
      location: "ROLLA",
    },
    {
      id: 63,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "ROLLA",
    },
    {
      id: 64,
      text: "I just love this place … so many varieties.",
      by: "Suzette, Ann Arbor",
      location: "ROLLA",
    },
    {
      id: 65,
      text:
        "The staff is great.  They are helpful, and patient and answer my questions no matter how strange. They also have different offers of the week.  Stay healthy and safe.",
      by: "Cottrell, Rockville",
      location: "Maggie's - Baltimore",
    },
    {
      id: 66,
      text:
        "Call me biased, but God I love this place I haven't found any other place that has better deals than this one.",
      by: "Joseph, Rockville",
      location: "Maggie's - Baltimore",
    },
    {
      id: 67,
      text:
        "My daily spot for all the great deals and variety, Liberty has become my favorite in the county because of many reasons, primarily the staff!",
      by: "Mario, Rockville",
      location: "Maggie's - Baltimore",
    },
    {
      id: 68,
      text:
        "Love liberty they have the best staff. The products are good and they have deals daily. I recommend going here for the best quality and price.",
      by: "Aaliyah, Rockville",
      location: "Maggie's - Baltimore",
    },
  ],
}

module.exports = testimonials
