const contactStrings =
{
	title: "Contact Us",
    link: "/contact-us/",
    description:
      "Questions about the dispensary experience? Check out our FAQs for fast answers to common questions. Otherwise, feel free to contact us directly. Send us an email, Call your dispensary.",
   	contact_us: "Contact Us",
	contact_url: "/contact-us",
	contact_us_desc1: "Questions about the dispensary experience? Check out our",
	contact_us_desc_faq_link: "FAQs",
	contact_us_desc2: "for fast answers to common questions. Otherwise, feel free to contact us directly.",
	contact_big_btn1: "Send us an email",
	contact_big_btn1_desc: "For general questions, please complete the form below and we will get back to you soon.",
	contact_big_btn2: "Call your Dispensary",
	contact_big_btn2_desc: "For product inquiries or questions about your local dispensary, please contact the store directly.",
	contact_email_form_title: "Send us a message and we’ll get back to you soon!",
	contact_email_btn_val: "Send",
  	validationError: "*Required field. Please complete all required fields and press send.",
	contact_email_thanks_title: "Thank you for reaching out!",
	contact_email_thanks_message: "One of our Wellness Guides will be in touch as soon as possible.",
}

module.exports = contactStrings