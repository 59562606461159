const pages = [
  {
    title: "Shop",
    link: "/shop/",
    description:
      "Shop a variety of flower, vapes, concentrates, edibles, and more through your local Liberty location",
    content: "",
  },
]

module.exports = pages