const rewardStrings =
{
	title: "Rewards",
    link: "/rewards/",
    description:
      "Life with cannabis is rewarding. Liberty Rewards is our way of saying thank you for trusting us with your dispensary experience. It’s also a great way to get more of your favorite products or explore something new.",

	rewards_title: "Rewards",
	comingSoonNotice: "New rewards program coming July 1!",
	rewardsBanner1: "Life with cannabis is",
	rewardsBanner2: "rewarding",
	rewardsBanner3: "The new Liberty Rewards Program gives you credits back on every purchase you make",
	rewardsBanner3Easthampton: "The new Liberty Rewards Program gives you credits back on every purchase you make*",
	rewardsBanner4: "You can use your Liberty Wallet to make cashless purchases, earn credits, and more!",
	rewardsListTitle: "The more you spend, the more you get back",
	rewardsCurrency: "$",
	offer1: "Spend $1 - $49……………………………………….",
	offer2: "Spend $50 - $149……………………………………",
	offer3: "Spend $150 - $299…………………………………",
	offer4: "Spend $300 - $599…………………………………",
	offer5: "Spend $600 - $999…………………………………",
	offer6: "Spend $1000 or more……………………………..",
	rewardsText1: "get 1% back",
	rewardsText2: "get 5% back",
	rewardsText3: "get 10% back",
	rewardsText4: "get 15% back",
	rewardsText5: "get 20% back",
	rewardsText6: "get 25% back",
	offerConstraints1: "You must have Liberty Wallet downloaded in order to earn rewards.",
	offerConstraints2: "You must create a profile and provide an email address...make sure the email address is the same one we have on file so all your transactions can sync up with Liberty Wallet!",
	offerConstraints3: "When you use the Liberty Wallet app, you can easily see how many credits you’ve earned",
	offerConstraints4: "You can pay for purchases using Liberty Wallet, or however you usually pay in store. Either way you’ll be earning rewards! Credits can be used on future purchases.",

	rewardsImageAlt1: "Cannabis Concentrate",
	rewardsImageAlt2: "Cannabis oil cartridges",
	rewardsImageAlt3: "Isolated Cannabis Bud",
	rewardsImageAlt4: "Rewards in mobile",
  rewardsImageAlt5: "Liberty Wallet",

	perksTitle: "Earn monthly perks—another way to get rewarded for what you buy",
	perk1: "3 levels of perks when you spend $250, $500, and $750 in a month",
	perk2: "Perks vary month to month and can vary by location. Check the menu at your local store for details ",
	perk3: "Liberty Wallet tracks your monthly perks progress",
	perk4: "They expire each month — hurry back before they disappear!",
	perksFootNote: "Monthly perks are only available on purchases made through Liberty Wallet and reset every month.",

	walletTitle: "Liberty Wallet is free credits and so much more",
	walletFeatureTitle1: "Transfer funds ",
	walletFeatureDesc1: "from your bank into Liberty Wallet. These funds become credits you can use at any Liberty location.",
	walletFeatureTitle2: "Use credits ",
	walletFeatureDesc2: "in your Liberty Wallet. These include credits transferred from your bank and free credits earned from previous purchases.",
	walletFeatureTitle3: "Monthly perks ",
	walletFeatureDesc3: "tracker tells you how much you’ve spent this month and shows you how far you are from each perk level.",
	walletFeatureTitle4: "Check inventory ",
	walletFeatureDesc4: "at any Liberty location.",
	walletFeatureTitle5: "View transactions ",
	walletFeatureDesc5: "including purchases, transfers, and credits earned.",

	videoTitle: "Interested in learning more about Liberty Wallet?",
	PDFTitle: "Download instructions for Liberty Wallet",
	pdfDownloadButton: "Download PDF",

	rewardFootNoteEasthampton: "*For Medical Patients only",
	rewardFootNote: "",

	AppTitle: "Download the app to your mobile device today!",
	appStoreButton: "Download at the App Store",
	playStoreButton: "Android app at Google Play",
	androidTrademark: "Android is a trademark of Google LLC.",
	appleTrademark: "Apple is a registered trademark of Apple Inc.",
	playStoreLink: "https://play.google.com/store/apps/details?id=org.libertypaymobile.application",
	appStoreLink: "https://apps.apple.com/us/app/liberty-wallet/id1530472010",
}

module.exports = rewardStrings
